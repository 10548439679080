<template>
  <div>

    <div class="page-title-area page-title-area-bg bg-15" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <h2>Latest News</h2>
            <p>
              Here are the latest articles about our impact and the contributions by our supporters
            </p>
          </div>
        </div>
      </div>
    </div>

    <NewsReview :offset="offset" :limit="limit" @goPrevPage="prevPage" @goNextPage="nextPage" />

  </div>
</template>

<script>
import NewsReview from "../components/news/NewsReview";

export default {
  name: "News",
  data() {
    return {
      offset: 0,
      limit: 6,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.init();
  },
  methods: {
    init() {
      if (this.$route.params.offset >= 0) {
        this.offset = this.$route.params.offset;
      } else {
        this.$router.push({path: '/'});
      }
    },
    prevPage() {
      this.offset = +this.offset - +this.limit;
      this.$router.push({
        name: 'news',
        params: { offset: this.offset}
      })
    },
    nextPage() {
      this.offset = +this.offset + +this.limit;
      this.$router.push({
        name: 'news',
        params: { offset: this.offset}
      });
    }
  },
  components: {
    NewsReview,
  },
  watch: {
    '$route.params': {
      immediate: true,
      handler() {
        this.init();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.page-navigation-area .page-link {
  width: auto;
  display: inline-flex;
}

.page-navigation-area .page-link i {
  margin-right: 6px;
  font-size: 21px;
  margin-left: 6px;
}
.default-btn[disabled=disabled] {
    opacity: .6;
    cursor: not-allowed;
}

.blog-item .content h3 {
  height: 82px;
}

.page-title-area-bg {
  background-image: url("../assets/img/new-banner.jpg");
  background-size: cover;
  background-position: 0 32%;
  text-align: left;
  padding-top: 90px;
  padding-bottom: 75px;

  h2, p {
    color: #fff;
  }

  p {
    font-size: 17px;
  }
}
</style>
