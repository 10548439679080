<template>
  <section id="newsSection" class="blog-section ptb-100" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
    <div class="loading" v-if="news_data === undefined && !news_error">
      <Loading />
    </div>
    <div class="container" v-if="news_data">
      <div class="row">
        <div
          class="col-lg-4 col-md-6"
          v-for="news in news_data.data"
          :key="news.id"
        >
          <div class="blog-item">
            <div class="image">
              <a :href="news.link" target="_blank" class="d-block image" rel="noreferrer" :class="{'news_isValidating': news_isValidating}">
                <DefaultImage :id="news.image" :height="'235px'" />
              </a>
            </div>
            <ul class="post-meta">
              <li :class="{'news_isValidating': news_isValidating}">
                <i class="fa fa-calendar"></i>
                {{ news.date ? moment(news.date) : "-" }}
              </li>
            </ul>
            <div class="content">
              <h3>
                <a :href="news.link" target="_blank" rel="noreferrer" :class="{'news_isValidating': news_isValidating}">
                  {{ news.name }}
                </a>
              </h3>
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="page-navigation-area">
            <nav aria-label="Page navigation example text-center">
              <ul class="pagination">
                <li class="slider-btn m-3">
                  <button
                    class="default-btn"
                    @click.prevent="goPrevPage()"
                    :disabled="news_data.data && +offset === 0"
                  >
                    <i class="fa fa-chevron-left"></i> Back Page
                  </button>
                </li>
                <li class="slider-btn m-3">
                  <button
                    class="default-btn"
                    @click.prevent="goNextPage()"
                    :disabled="news_data && news_data.data.length < 6"
                  >
                    Next Page <i class="fa fa-chevron-right"></i>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import useSWRV from "swrv";
  import api from "../../services/api";
  import Loading from "../Loading";
  import DefaultImage from "../DefaultImage";
  import moment from "moment";

  export default {
    name: "NewsReview",
    components: {DefaultImage, Loading},
    props: ['offset', 'limit'],
    setup(props) {
      const {
        data: news_data,
        error: news_error,
        isValidating: news_isValidating
      } = useSWRV(() => `items/news?status=published&start=date&sort=-date&offset=${props.offset}&limit=${props.limit}`, api.fetcher);

      return {
        news_data,
        news_error,
        news_isValidating
      };
    },
    methods: {
      moment(date) {
        return moment(date).format("DD MMM YYYY");
      },
      goPrevPage() {
        if(this.offset !==0 ) {
          this.$emit('goPrevPage');
        }
        document.getElementById('newsSection').scrollIntoView({behavior: "smooth", block: "start"});
      },
      goNextPage() {
        this.$emit('goNextPage');
        document.getElementById('newsSection').scrollIntoView({behavior: "smooth", block: "start"});
      },
    }
  }
</script>

<style scoped lang="scss">
  .blog-item {
    min-height: 440px;
  }
  a.d-block.image {
    position: relative;

    &.news_isValidating:after {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: url("../../assets/img/placeholder.gif");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      z-index: 16;
      content: "";
    }
  }

  .post-meta {
    li {
      position: relative;

      &.news_isValidating:after {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: url("../../assets/img/placeholder.gif");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: 16;
        content: "";
        border-radius: 7px;
      }
    }
  }
  .content {
    h3 {
      a {
        position: relative;
        display: block;

        &.news_isValidating:after {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-image: url("../../assets/img/placeholder.gif");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          z-index: 16;
          content: "";
          border-radius: 7px;
        }
      }
    }
  }

  .default-btn:disabled {
    background-color: #bdbdbd;
    border-color: #bdbdbd;
    cursor: not-allowed;

    &:hover {
      color: #fff;
    }
  }
</style>
